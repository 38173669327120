define("discourse/plugins/discourse-cakeday/discourse/initializers/cakeday", ["exports", "discourse/lib/plugin-api", "I18n", "discourse/plugins/discourse-cakeday/discourse/lib/cakeday"], function (_exports, _pluginApi, _I18n, _cakeday) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeCakeday(api) {
    const currentUser = api.getCurrentUser();
    if (!currentUser) {
      return;
    }
    const store = api.container.lookup("service:store");
    store.addPluralization("anniversary", "anniversaries");
    const siteSettings = api.container.lookup("service:site-settings");
    const emojiEnabled = siteSettings.enable_emoji;
    const cakedayEnabled = siteSettings.cakeday_enabled;
    const birthdayEnabled = siteSettings.cakeday_birthday_enabled;
    if (cakedayEnabled) {
      api.includePostAttributes("user_cakedate");
      api.addPosterIcon((_, _ref) => {
        let {
          user_cakedate,
          user_id
        } = _ref;
        if ((0, _cakeday.cakeday)(user_cakedate)) {
          let result = {};
          if (emojiEnabled) {
            result.emoji = siteSettings.cakeday_emoji;
          } else {
            result.icon = "cake-candles";
          }
          if (user_id === currentUser?.id) {
            result.title = _I18n.default.t("user.anniversary.user_title");
          } else {
            result.title = _I18n.default.t("user.anniversary.title");
          }
          return result;
        }
      });
    }
    if (birthdayEnabled) {
      api.includePostAttributes("user_birthdate");
      api.addPosterIcon((_, _ref2) => {
        let {
          user_birthdate,
          user_id
        } = _ref2;
        if ((0, _cakeday.birthday)(user_birthdate)) {
          let result = {};
          if (emojiEnabled) {
            result.emoji = siteSettings.cakeday_birthday_emoji;
          } else {
            result.icon = "cake-candles";
          }
          if (user_id === currentUser?.id) {
            result.title = _I18n.default.t("user.date_of_birth.user_title");
          } else {
            result.title = _I18n.default.t("user.date_of_birth.title");
          }
          return result;
        }
      });
    }
    if (cakedayEnabled || birthdayEnabled) {
      if (cakedayEnabled) {
        api.addCommunitySectionLink({
          name: "anniversaries",
          route: "cakeday.anniversaries.today",
          title: _I18n.default.t("anniversaries.title"),
          text: _I18n.default.t("anniversaries.title"),
          icon: "cake-candles"
        }, true);
      }
      if (birthdayEnabled) {
        api.addCommunitySectionLink({
          name: "birthdays",
          route: "cakeday.birthdays.today",
          title: _I18n.default.t("birthdays.title"),
          text: _I18n.default.t("birthdays.title"),
          icon: "cake-candles"
        }, true);
      }
    }
  }
  var _default = _exports.default = {
    name: "cakeday",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.1", api => initializeCakeday(api));
    }
  };
});