define("discourse/plugins/discourse-cakeday/discourse/components/emoji-images", ["exports", "@ember/component", "@ember-decorators/component", "discourse/lib/text", "discourse-common/utils/decorators", "I18n", "@ember/template-factory"], function (_exports, _component, _component2, _text, _decorators, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.siteSettings.enable_emoji}}
    <div title={{this.titleText}}>
      {{#each this.emojiHTML as |html|}}
        {{html-safe html}}
      {{/each}}
    </div>
  {{else}}
    {{d-icon "cake-candles" title=this.titleText}}
  {{/if}}
  */
  {
    "id": "CrIgeG/X",
    "block": "[[[41,[30,0,[\"siteSettings\",\"enable_emoji\"]],[[[1,\"  \"],[10,0],[15,\"title\",[30,0,[\"titleText\"]]],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"emojiHTML\"]]],null]],null],null,[[[1,\"      \"],[1,[28,[35,4],[[30,1]],null]],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,5],[\"cake-candles\"],[[\"title\"],[[30,0,[\"titleText\"]]]]]],[1,\"\\n\"]],[]]]],[\"html\"],false,[\"if\",\"div\",\"each\",\"-track-array\",\"html-safe\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-cakeday/discourse/components/emoji-images.hbs",
    "isStrictMode": false
  });
  const EmojiImages = dt7948.c(class EmojiImages extends _component.default {
    emojiHTML(list) {
      return list.split("|").map(et => (0, _text.emojiUnescape)(`:${et}:`, {
        skipTitle: true
      }));
    }
    static #_ = (() => dt7948.n(this.prototype, "emojiHTML", [(0, _decorators.default)("list")]))();
    titleText(title) {
      return _I18n.default.t(title);
    }
    static #_2 = (() => dt7948.n(this.prototype, "titleText", [(0, _decorators.default)("title")]))();
  }, [(0, _component2.classNames)("emoji-images")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EmojiImages);
});