define("discourse/plugins/hosted-site/discourse/components/invoice-detail-error", ["exports", "@glimmer/component", "discourse-common/helpers/d-icon", "@ember/component", "@ember/template-factory"], function (_exports, _component, _dIcon, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class InvoiceDetailError extends _component.default {
    get validationError() {
      return this.args.validationErrors?.[this.args.fieldName];
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.validationError}}
          <div class="invoice-details-error">
            {{icon "xmark"}}
            {{this.validationError}}
          </div>
        {{/if}}
      
    */
    {
      "id": "IS1ELpHl",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"validationError\"]],[[[1,\"      \"],[10,0],[14,0,\"invoice-details-error\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"xmark\"],null]],[1,\"\\n        \"],[1,[30,0,[\"validationError\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/hosted-site/discourse/components/invoice-detail-error.js",
      "scope": () => [_dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = InvoiceDetailError;
});